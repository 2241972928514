<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx">
      <v-card-title class="primary--text">
        New Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-skeleton-loader type="text" v-if="dataLoading"></v-skeleton-loader>
          <v-text-field
            v-else
            flat
            outlined
            label="Category Name"
            v-model="name"
            :rules="[rules.required]"
            :error-messages="errorMessageName"
          ></v-text-field>
        </v-sheet>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-skeleton-loader
            type="paragraph"
            v-if="dataLoading"
          ></v-skeleton-loader>
          <v-textarea
            v-else
            flat
            outlined
            label="Description"
            v-model="description"
            :rules="[rules.required]"
            :error-messages="errorMessageDes"
          ></v-textarea>
        </v-sheet>

        <h3 class="primary--text large font-weight-medium mb-3 mt-5">
          Modules & Permissions
        </h3>
        <v-row class="pb-4" v-if="dataLoading">
          <v-col cols="12" md="6">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title v-if="!dataLoading">
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
          cancel
        </v-btn>
        <v-btn color="primary" depressed @click="createCategory" :loading="loading">
          Create
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { addCategory } from "../../services/categoriesService";
import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    // 
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    errorMessageName: "",
    errorMessageDes: "",
    name: "",
    description: "",
    permissions: [],
    modules: [],
    addCategoryReturn: null,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageCategoryList" });
    },
    updatePermissions(permissions) {
      permissions.forEach((permission) => {
        if (!permission.value) {
          this.permissions = this.permissions.filter(
            (element) => element !== permission.id
          );
        } else {
          if (!this.permissions.includes(permission.id))
            this.permissions.push(permission.id);
        }
      });
    },
    async createCategory() {
      if (this.name.trim() == "") {
        this.errorMessageName = "Required.";
      }

      if (this.description.trim() == "") {
        this.errorMessageDes = "Required.";
      }

      if (this.name && this.description) {
        let xper = [];
        this.permissions.forEach((permission) => {
          xper.push({ permission_id: permission });
        });

        let data = {
          uuid: uuidv4(),
          name: this.name,
          description: this.description,
          permission_ids: this.permissions,
          category_permissions: xper,
        };
        this.loading = true;
        this.addCategoryReturn = await addCategory(data);
        this.loading = false;
        if (this.addCategoryReturn == "success") {
          this.goBack();
        }
      }
    },
  },
};
</script>